import "./App.css";
import HomePageWithToken from "./pages/HomePageWithStatus";
import Home from "./pages/HomePage/HomePage";

function App() {
  return (
    <div className="font-poppins select-none">
      <HomePageWithToken />
    </div>
  );
}

export default App;
