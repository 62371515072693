import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import ImageSlider from "../../components/ImageSlider";
import { useQuery } from "@tanstack/react-query";
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import Constant from "../../globalValue";
import socketIOClient from "socket.io-client";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3c72f6",
    color: theme.palette.common.white,
    fontSize: 50,
    padding: "30px",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 50,
    paddingTop: "0px",
    paddingLeft: "20px",
    paddingBottom: "0px",
    paddingRight: "20px",
    fontWeight: 900,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#a9c2ff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function HomePage() {
  const audioRef = useRef(null);
  const audioPlaying = useRef(false);
  const [counterData, setCounterData] = useState(null);
  const [announcedToken, setAnnouncedToken] = useState(null);
  const [lastTokenValue, setLastTokenValue] = useState(null);
  const [isReconnected, setIsReconnected] = useState(false);
  const [error, setError] = useState(null);
  const baseURL = Constant.baseUrl;
  useEffect(() => {
    const socket = socketIOClient(Constant.domain, {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      randomizationFactor: 0.5,
    });

    const emitDataRequest = () => {
      socket.emit("companyIdAndDepId", {
        companyId: Constant.companyID,
        depId: Constant.departmentID,
        isCall: Constant.isCall,
      });
    };

    socket.on("databaseChanges", (changes) => {
      setCounterData(changes);
      setAnnouncedToken(
        changes.result.find((item) => item.IsAnnounced === false)
      );
    });

    socket.on("connect", () => {
      setIsReconnected(true);
      emitDataRequest();
    });

    socket.on("disconnect", () => {
      setIsReconnected(false);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("databaseChanges");
      socket.disconnect();
    };
  }, []);
  const setPlayEnded = async () => {
    const tokenStatusUpdate = `${baseURL}token/IsAnnounced/${Constant.companyID}/${Constant.departmentID}/${announcedToken?.TokenValue}`;
    const tokenStatusResponse = await axios.put(tokenStatusUpdate, {});
    if (tokenStatusResponse.data.status === "success") {
      audioPlaying.current = false;
    }
  };
  useEffect(() => {
    if (announcedToken?.TokenValue !== lastTokenValue) {
      const fetchData = async () => {
        try {
          const apiUrl = `${baseURL}token/onTokenAudio/${announcedToken.TokenValue}/${announcedToken.CounterName}`;
          setLastTokenValue(announcedToken?.TokenValue);
          const response = await axios.get(apiUrl, {
            headers: {
              "Content-Type": "blob",
              Accept: "blob",
            },
            responseType: "blob",
          });

          const blob = response.data;
          const objectURL = URL.createObjectURL(blob);
          audioRef.current.src = objectURL;
          audioRef.current.preload = "auto";

          if (!audioPlaying.current) {
            audioPlaying.current = true;

            await audioRef.current.play();
          }
        } catch (error) {
          setError(error);
        }
      };

      if (audioPlaying.current === false) {
        fetchData();
      }

      return () => {
        if (audioRef.current.src) {
          URL.revokeObjectURL(audioRef.current.src);
        }
      };
    }
  }, [announcedToken]);

  /* Get user Advertisements */
  const { data: advertisement } = useQuery({
    queryKey: ["advertisement"],
    queryFn: async () => {
      try {
        const response = await fetch(
          `${baseURL}advertisement/${Constant.companyID}/${Constant.departmentID}`
        );
        const json = await response.json();
        return json;
      } catch (error) {
        throw new Error("Error fetching data");
      }
    },
    refetchInterval: 1000,
  });

  let advImages = [];

  if (advertisement && advertisement.advertisement) {
    const sortedAdvertisements = advertisement.advertisement
      .filter((advertisement) => advertisement.Status === true)
      .sort((a, b) => a.AdvOrder - b.AdvOrder);
    advImages = sortedAdvertisements.map((advertisement) => {
      return `https://apiimage.stslive.in/TMS/assest/uploads?fileName=${advertisement.AdvFileUrl}`;
    });
  }
  const oddTokens = counterData?.result?.filter(
    (item, index) => index % 2 === 1
  );
  const evenTokens = counterData?.result?.filter(
    (item, index) => index % 2 === 0
  );
  const isAdvertised = advImages?.length === 0;
  return (
    <div>
      <div className="hidden">
        <audio ref={audioRef} controls autoPlay onEnded={setPlayEnded}>
          Your browser does not support the audio element.
        </audio>
      </div>
      <div className={`${isAdvertised ? "" : "grid grid-cols-2 gap-2"}`}>
        <div
          className={`${
            isAdvertised ? "hidden" : "block"
          } min-h-[82vh] max-h-screen`}
        >
          <ImageSlider images={advImages} />
        </div>
        <div
          className={`${
            isAdvertised ? "grid grid-cols-2 gap-2" : "hidden"
          } overflow-x-auto overflow-y-auto max-h-screen`}
        >
          <TableContainer>
            <Table className="h-fit">
              <TableHead>
                <TableRow>
                  {counterData?.counterCount > 1 && (
                    <StyledTableCell align="left">Counter</StyledTableCell>
                  )}
                  <StyledTableCell align="center">Token</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {evenTokens?.map((token, index) => (
                  <StyledTableRow key={index + 1}>
                    {counterData?.counterCount > 1 && (
                      <StyledTableCell align="left">
                        {token.CounterName || "Counter Name"}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="center">
                      {token.TokenValue}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer>
            <Table className="h-fit">
              <TableHead>
                <TableRow>
                  {counterData?.counterCount > 1 && (
                    <StyledTableCell align="left">Counter</StyledTableCell>
                  )}
                  <StyledTableCell align="center">Token</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {oddTokens?.map((token, index) => (
                  <StyledTableRow key={index + 1}>
                    {counterData?.counterCount > 1 && (
                      <StyledTableCell align="left">
                        {token.CounterName || "Counter Name"}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="center">
                      {token.TokenValue}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div
          className={`${
            isAdvertised ? "hidden" : "block"
          } overflow-x-auto overflow-y-auto max-h-screen`}
        >
          <TableContainer>
            <Table className="h-fit">
              <TableHead>
                <TableRow>
                  {counterData?.counterCount > 1 && (
                    <StyledTableCell align="left">Counter3</StyledTableCell>
                  )}
                  <StyledTableCell align="center">Token</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {counterData?.result?.map((token, index) => (
                  <StyledTableRow key={index + 1}>
                    {counterData?.counterCount > 1 && (
                      <StyledTableCell align="left">
                        {token.CounterName || "Counter Name"}
                      </StyledTableCell>
                    )}
                    <StyledTableCell align="center">
                      {token.TokenValue}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className="text-center text-lg mt-1 mb-2 fixed bottom-0 w-full bg-white">
        <div className="text-center">Powered by Xenia Technologies</div>
      </div>
    </div>
  );
}

export default HomePage;
